import React, { useState, useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../layouts/index';
import Img from 'gatsby-image';
import translationsEn from '../locales/en.json';
import translationsEs from '../locales/es.json';

const Home = ({ data }) => {
  const products = data.products.edges;
  const { site } = data;

  const [locale, setLocale] = useState('es');

  useEffect(() => {
    if (typeof window !== "undefined") {
      const storedLocale = localStorage.getItem('locale') || 'es';
      setLocale(storedLocale);
    }

    const handleLanguageChange = (event) => {
      setLocale(event.detail);
    };

    if (typeof window !== "undefined") {
      window.addEventListener('languageChanged', handleLanguageChange);
    }
    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener('languageChanged', handleLanguageChange);
      }
    };
  }, []);
  
  const translations = locale === 'es' ? translationsEs : translationsEn;

  return (
    <Layout
      site={site}
      seo={{ ...site.globalSeo, ...site.faviconMetaTags }}
      lang={locale}
      title={"OS DO CASAL - Tienda Oficial"}
      description={"Bienvenido a OS DO CASAL. Somos los número uno en cuanto a venta de calcetines, videojuegos y consolas de la saga Pokémon."}
    >
      <div className="Catalogue">
        {products
        .filter(({ node: product }) => product.locale === locale)
        .map(({ node: product }) => (
          <div className="Catalogue__item" key={product.id}>
            <Link to={`/${locale}/product/${product.seourl}`} aria-label={product.name}>
              <div className="Product__image">
                <Img fluid={product.image.fluid} loading="lazy" />
              </div>
              <div className="Product__details">
                <div className="Product__name">
                  {product.name}
                  <div className="Product__price">{product.price}€</div>
                </div>
              </div>
            </Link>
            <div
              className="Product snipcart-add-item"
              data-item-id={product.id}
              data-item-price={product.price}
              data-item-image={product.image.url}
              data-item-name={product.name}
              data-item-url={`/${locale}/product/${product.seourl}`}
              data-item-custom1-name="SKU"
              data-item-custom1-value={product.sku}
            >
              <span className="Product__buy">{translations['buy']}</span>
            </div>
          </div>
        ))
        }
      </div>
    </Layout>
  );
};

export const query = graphql`
  query CatalogueQuery($locale: String) {
    products: allDatoCmsProduct(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          id
          sku
          seourl
          name
          price
          locale
          image {
            url
            fluid(maxWidth: 300, imgixParams: { fm: "webp" }) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
    site: datoCmsSite {
      faviconMetaTags {
        tags
      }
      globalSeo {
        siteName
      }
    }
  }
`;

export default Home;